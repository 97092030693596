/* SLIDE UP */
const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.boxSizing = `border-box`
  target.style.height = target.offsetHeight + `px`
  target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  window.setTimeout(() => {
    target.style.display = `none`
    target.style.removeProperty(`height`)
    target.style.removeProperty(`padding-top`)
    target.style.removeProperty(`padding-bottom`)
    target.style.removeProperty(`margin-top`)
    target.style.removeProperty(`margin-bottom`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
    // alert("!")
  }, duration)
}

/* SLIDE DOWN */
const slideDown = (target, duration = 500) => {
  target.style.removeProperty(`display`)
  let display = window.getComputedStyle(target).display
  if (display === `none`) {
    display = `block`
  }
  target.style.display = display
  const height = target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.offsetHeight
  target.style.boxSizing = `border-box`
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.height = height + `px`
  target.style.removeProperty(`padding-top`)
  target.style.removeProperty(`padding-bottom`)
  target.style.removeProperty(`margin-top`)
  target.style.removeProperty(`margin-bottom`)
  window.setTimeout(() => {
    target.style.removeProperty(`height`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
  }, duration)
}

/* TOOGGLE */
const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === `none`) {
    return slideDown(target, duration)
  } else {
    return slideUp(target, duration)
  }
}

(function ($) {
  $(document).ready(function () {
    svg4everybody({})
  })

})(jQuery)

// set vh height
window.addEventListener("resize",function(){
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
})
// set vh height end

window.addEventListener("load",function(){
  let body = this.document.querySelector("body");
  let html = this.document.querySelector("html");

  body.style.opacity = 1

  // set vh height
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // set vh height end

  // aos
  setTimeout(function(){
    AOS.init({
      duration: 1000,
      once: true,
    });
  },10)
  // aos end

  // header menu ======================================================
  let headerMenu = document.querySelector(".header-mobile__menu")
  let headerBurger = document.querySelector(".header__burger")
  let overlay = document.querySelector(".overlay")
  if(headerMenu && headerBurger){
    let closeMenu = function(){
      headerMenu.classList.remove("active")
      headerBurger.classList.remove("active")
      body.classList.remove("lock")
      html.classList.remove("lock")
      if(overlay){
        overlay.classList.remove("active")
      }
    }

    headerBurger.addEventListener('click',function(){
      headerMenu.classList.toggle("active")
      headerBurger.classList.toggle("active")
      body.classList.toggle("lock")
      html.classList.toggle("lock")
      if(overlay){
        overlay.classList.toggle("active")
      }
    })

    // anchor
    let anchorTrigger = document.querySelectorAll(".anchor-trigger")
    let anchorBlock = document.querySelectorAll(".anchor-block")
    let header = document.querySelector(".header")
    if(anchorBlock && anchorTrigger && header){
      anchorTrigger.forEach(trigger=>{
        trigger.addEventListener("click",function(e){
          e.preventDefault()
          anchorBlock.forEach(b=>{
            if(trigger.classList.contains("header__logo")){
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              })
            }else if(trigger.getAttribute("data-anchor").toLowerCase() == b.getAttribute("data-block").toLowerCase()){
              let boundingBlock = b.offsetTop
              window.scrollTo({
                top: boundingBlock - header.offsetHeight,
                behavior: "smooth"
              })
              if(headerMenu.classList.contains("active")){
                closeMenu()
              }
            }

          })

        })
      })

      // online check block
      let onlineCheck = function(){
        anchorBlock.forEach(scrollBlock=>{
          let topOffset = scrollBlock.offsetTop - (header.offsetHeight + 20)
          let allHeight = (scrollBlock.offsetTop + scrollBlock.offsetHeight) - (header.offsetHeight + 20)
          let stepId  = scrollBlock.getAttribute("data-block")
          let activeNav = document.querySelector(`.anchor-trigger[data-anchor=${stepId}]`)
          if(window.scrollY > topOffset && window.scrollY <= allHeight){
            if(activeNav){
              activeNav.classList.add('active')
            }
          }else{
            if(activeNav){
              activeNav.classList.remove('active')
            }
          }
        })
      }
      window.addEventListener("scroll",onlineCheck)
      onlineCheck()
      // online check bloxk end
    }
    // anchor end

    window.addEventListener("resize",function(){
      if(window.innerWidth > 1024 && headerMenu.classList.contains("active")){
        closeMenu()
      }
    })
  }
  // header menu end ======================================================




  // offer cards ======================================================
  let offerCards = document.querySelectorAll(".offer__card")
  if(offerCards){
    offerCards.forEach(card=>{
      let cardTrigger = card.querySelector(".offer-card__btn ")
      let cardPopup = card.querySelector(".offer-card-popup")
      if(cardTrigger && cardPopup){
        cardTrigger.addEventListener("click",function(){
          if(!card.classList.contains("active")){
            // remove active
            let activeCardOffer = document.querySelector(".offer__card.active")
            if(activeCardOffer && window.innerWidth > 1024){
              // let activePopup = activeCardOffer.querySelector('.offer-card-popup')
              activeCardOffer.classList.remove("active")
              // if(window.innerWidth < 1025){
              //   console.log(activePopup)
              //   slideUp(activePopup,300)
              // }
            }
            // add current
            card.classList.add("active")
            if(window.innerWidth < 1025){
              slideDown(cardPopup,300)
            }
          }
          else{
            card.classList.remove("active")
            if(window.innerWidth < 1025){
              slideUp(cardPopup,300)
            }
          }
        })

        // close btn
        let closeCardBtn = cardPopup.querySelector(".offer-card-popup__close")
        closeCardBtn && closeCardBtn.addEventListener("click",function(){
          card.classList.remove("active")
          if(window.innerWidth < 1025){
            slideUp(cardPopup,300)
          }
        })
      }

      // remove or add on resize
      window.addEventListener("resize",function(){
        if(window.innerWidth > 1024 && cardPopup){
          if(cardPopup.hasAttribute("style")){
            cardPopup.removeAttribute("style")
            card.classList.remove("active")
          }
        }
        if(window.innerWidth < 1025 && card.classList.contains("active")){
          if(cardPopup && !cardPopup.hasAttribute("style")){
            slideDown(cardPopup,300)
          }
        }
      })
    })
  }
  // offer cards ======================================================

  // swiper achievement ======================================================
  let swiperAchievement = new Swiper(".achievement-slider.swiper-container ", {
    speed: 800,
    spaceBetween:15,
    slidesPerView: 1,
    grabCursor: true,
    navigation: {
      nextEl: ".achievement-slider.swiper-container .slider-next",
      prevEl: ".achievement-slider.swiper-container .slider-prev",
    },
    breakpoints:{
      801:{
        spaceBetween: 15,
        slidesPerView: 1.7,
      },
      901:{
        spaceBetween: 15,
        slidesPerView: 2,
      },
      1025:{
        spaceBetween: 20,
        slidesPerView: 2,
      }
    }
  });
  // swiper achievement end ======================================================

  // swiper achievement ======================================================
  let swiperReviews = new Swiper(".review-slider.swiper-container ", {
    speed: 800,
    spaceBetween:15,
    slidesPerView: 1,
    grabCursor: true,
    navigation: {
      nextEl: ".review-slider.swiper-container .slider-next",
      prevEl: ".review-slider.swiper-container .slider-prev",
    },
    breakpoints:{
      701:{
        spaceBetween: 20,
        slidesPerView: 2,
      },
      1201:{
        spaceBetween: 40,
        slidesPerView: 3,
      },
      1451:{
        spaceBetween: 80,
        slidesPerView: 3,
      }
    }
  });
  // swiper achievement end ======================================================


  // accordeons  ======================================================
  let accordeonItems = document.querySelectorAll(".accordeon__item")
  if(accordeonItems){
    accordeonItems.forEach(accordeon=>{
      let accordeonTrigger = accordeon.querySelector(".accordeon__trigger")
      let accordeonContent = accordeon.querySelector(".accordeon__content")
      if(accordeonContent && accordeonTrigger){
        accordeonTrigger.addEventListener("click",function(){
          if(!accordeon.classList.contains("active")){
            let accordeonList = accordeon.closest(".accordeon__list")
            if(accordeonList){
              let activeAccordeon = accordeonList.querySelector(".accordeon__item.active")
              if(activeAccordeon){
                activeAccordeon.classList.remove("active")
                let activeContent = activeAccordeon.querySelector(".accordeon__content")
                slideUp(activeContent,300)

              }
            }
          }
          accordeon.classList.toggle("active")
          slideToggle(accordeonContent,300)

        })
      }
    })
  }
  // accordeons end  ======================================================


  // more detail  ======================================================
  let moreDetail = this.document.querySelectorAll(".more-detail__box")
  if(moreDetail){
    moreDetail.forEach(box=>{
      let moredetailBtn = box.querySelector(".more-detail-btn")
      let moredetailText = box.querySelector(".more-detail__text")
      if(moredetailBtn && moredetailText){
        moredetailBtn.addEventListener("click",function(){
          if(!box.classList.contains("show")){
            box.classList.add("show")
            slideDown(moredetailText,300)
            moredetailBtn.innerHTML = "Згорнути"
          }else{
            slideUp(moredetailText,300)
            box.classList.remove("show")
            moredetailBtn.innerHTML = "Детальніше"
          }
        })
      }
    })
  }
  // more detail  ======================================================

  // phone mask  ======================================================
  let maskPhoneFields = document.querySelectorAll(".phone-mask-field")
  if(maskPhoneFields){
    maskPhoneFields.forEach(maskInput=>{
      let phoneMask = IMask(
        maskInput, {
          mask: '+{38(0}00) 000-00-00'
        }
      )
    })
  }
  // phone mask end  ======================================================


  //validate  ======================================================
  const forms = document.querySelectorAll(".form-container")
  forms && forms.forEach(item => {
    // email patern
    let email = item.querySelector(".mail")
    if (email) {
      let parent = email.closest(".form-item")
      let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
      email.addEventListener("keyup", function (ev) {
        ev.stopPropagation();
        let value = email.value;
        if (value.match(pattern)) {
          parent.classList.remove("error__email")
        } else {
          parent.classList.add("error__email")
        }
        if (value.length < 1) {
          parent.classList.remove("error__email")
        }
      })
    }
    // email patern end

    item.addEventListener('submit', function (e) {
      e.preventDefault();
      let valide = true;
      const inputs = item.querySelectorAll('.form-required');

      // checkbox valid
      let checkbox=item.querySelector(".form__checkbox input")
      let checkboxParent=item.querySelector(".form__checkbox")
      if(checkbox){
        valide= false
        if(checkbox.checked){
          valide = true
          checkboxParent.classList.remove("error")
        }else{
          checkboxParent.classList.add("error")
          valide = false
        }
      }
      // checkbox valid end

      // empty fields valid
      inputs.forEach(element => {
        const parent = element.closest('.form-item');
        if (element.value.trim() === '') {
          parent.classList.add('error');
          valide = false;
        } else {
          parent.classList.remove('error');
        }
      })
       // empty fields valid end

      // email validate
      let emailField = item.querySelector(".mail")
      if(email){
        let emailParent = emailField.closest(".form-item")
        if(emailParent && emailParent.classList.contains("error__email")){
          valide = false;
        }
      }
      // email validate end

      // phone numbers valid
      let phoneInputs = item.querySelectorAll(".phone-mask-field")
      if(phoneInputs){
        phoneInputs.forEach(phoneInput=>{
          let phoneParent = phoneInput.closest(".form-item")
          if(phoneInput.value.length < 18){
            phoneParent.classList.add("error")
            valide = false
          }
        })
      }
      // phone numbers valid end

      if (valide) {
        fetch('/mail.php', {
          method: 'POST',
          body: new FormData(item)
        }).then(function (response) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push ({
            'event': 'formSuccess',
            'formName': "'" + item.querySelector('input[name="CallTarget"]').value + "'",
          });
          if (response.ok) {
            return response.json();
          } else if (response.status == 422) {
            // process back validation
          }
          return Promise.reject(response);
        }).then(function (data) {

        })["catch"](function (error) {
          console.warn(error);
        });
        // clear on sent
        let allInputs = item.querySelectorAll(".form__input")
        if(allInputs){
          allInputs.forEach(el=>{
            el.value = "";
          })
        }
        // clear on sent end

        // clear checkbox
        let checkboxInput = item.querySelectorAll('.form__checkbox input')
        if(checkboxInput){
          checkboxInput.forEach(checkForm=>{
            checkForm.checked = false
          })
        }
        // clear checkbox end

        // close active popup on sent and add thanks popup
        let activePopup = document.querySelector(".modal-wraper.active")
        let thanksPopup = document.querySelector(".modal-wraper.thanks-popup")
        let quizeThanks = document.querySelector(".modal-wraper.quize-thanks-popup")
        if(activePopup){
          activePopup.classList.remove("active")
          if(!activePopup.classList.contains("quize-modal-wrapper")){
            if(thanksPopup ){
              setTimeout(function(){
                thanksPopup.classList.add("active")
              },100)
            }
          }else{
            if(quizeThanks){
              setTimeout(function(){
                quizeThanks.classList.add("active")
                // reset quize
                let quizeSteps = document.querySelectorAll(".quize-stepper__item")
                let quizeProgressbar = document.querySelector(".quize-stepper-progress")
                if(quizeSteps && quizeProgressbar){
                  quizeSteps.forEach(stepQuize=>{
                    let quizeStepInfo = stepQuize.querySelector(".quize-stepper__item-info")
                    let quizeStepNavigation = stepQuize.querySelector(".quize-stepper__navigation")
                    let quizeStepRadios = stepQuize.querySelectorAll(".form__radio input")
                    if(parseInt(stepQuize.getAttribute("data-step")) == 1){
                      stepQuize.classList.remove("disable-display")
                      stepQuize.classList.add('active')
                    }else{
                      stepQuize.classList.remove("active")
                      stepQuize.classList.add("disable-display")
                    }
                    if(quizeStepInfo){
                      quizeStepInfo.classList.remove("disable-display")
                      quizeStepInfo.classList.add("active")
                    }
                    if(quizeStepNavigation){
                      quizeStepNavigation.classList.add("disable-display")
                      quizeStepNavigation.classList.remove("active")
                    }
                    if(quizeStepRadios){
                      quizeStepRadios.forEach(radioQuize=>{
                        radioQuize.checked = false
                      })
                    }
                    quizeProgressbar.classList.remove("disable-display")
                    let quizeProgressbarValue = quizeProgressbar.querySelector(".value")
                    let quizeProgressbarFill = quizeProgressbar.querySelector(".quize-stepper-progress__fill")
                    let quizeProgressbarStatus = quizeProgressbar.querySelector(".quize-stepper-progress__status")
                    if(quizeProgressbarValue && quizeProgressbarStatus){
                      quizeProgressbarValue.innerHTML = 1
                      quizeProgressbarStatus.style.marginLeft = 0 + "%"
                    }
                    if(quizeProgressbarFill){
                      quizeProgressbarFill.style.width = 0 + "%"
                    }
                  })
                }
                // reset quize end
              },100)
            }
          }
        }
        // close active popup on sent and add thanks popup end

      }
    })
  })
  // clear on input
  const inputsField = document.querySelectorAll('.form-item');
  inputsField.forEach(field=>{
    let inputField = field.querySelector("input")
    if(inputField){
      inputField.addEventListener("input",function(){
        field.classList.remove("error")
      })
    }
  })
  // clear on input end
  // validate end   ======================================================

  // modals  ======================================================
  let modalsTrigger = document.querySelectorAll(".modal-trigger__btn")
  let modalsBox = document.querySelectorAll(".modal-wraper")
  if(modalsTrigger && modalsBox){
    modalsTrigger.forEach(trigerModal=>{
      trigerModal.addEventListener("click",function(){
        modalsBox.forEach(modalBox=>{
          if(trigerModal.getAttribute("data-type").toLowerCase() == modalBox.getAttribute("data-modal").toLowerCase()){
            modalBox.classList.add("active")
            body.classList.add("lock")
            html.classList.add("lock")
            let callTargetInput = modalBox.querySelector(".from-target-input")
            if(callTargetInput){
              callTargetInput.value = trigerModal.getAttribute("data-place")
            }
          }
        })
      })
    })
    // close popup
    modalsBox.forEach(box=>{
      let closeModalFunc = function(){
        body.classList.remove("lock")
        html.classList.remove("lock")
        box.classList.remove("active")
      }
      let closeModal = box.querySelectorAll(".modal-close__trigger")
      if(closeModal){
        closeModal.forEach(btnclose=>{
          btnclose.addEventListener("click", closeModalFunc)

        })
      }
      window.addEventListener("click",function(e){
        if(e.target == box){
          closeModalFunc()
        }
      })
    })
    // close popup end
  }
  // modals end  ======================================================

  // smooth scroll  ======================================================
  SmoothScroll({
    // Время скролла 400 = 0.4 секунды
    animationTime    : 900,
    // Размер шага в пикселях
    stepSize         : 90,
    // Ускорение
    accelerationDelta : 40,
    // Максимальное ускорение
    accelerationMax   : 2,

    // Поддержка клавиатуры
    keyboardSupport   : true,
    // Шаг скролла стрелками на клавиатуре в пикселях
    arrowScroll       : 90,
    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm   : true,
    pulseScale       : 4,
    pulseNormalize   : 1,
    // Поддержка тачпада
    touchpadSupport   : true,
  })
  // smooth scroll end ======================================================


  // quize popup ======================================================
  let modalQuizebox = document.querySelector(".modal-quize-box")
  let modalQuizeSteps = document.querySelectorAll(".quize-stepper__item")
  if(modalQuizebox && modalQuizeSteps){
    let stepsQuantity = modalQuizeSteps.length
    // progressbar count function
    let countQuizeProgress = function(stepsQuantity){
      let activeStep = document.querySelector(".quize-stepper__item.active")
      if(activeStep && activeStep.hasAttribute("data-step")){
        let progressStatus = document.querySelector(".quize-stepper-progress__status")
        let progressFill = document.querySelector(".quize-stepper-progress__fill")
        if(progressFill && progressStatus){
          let progressValue = progressStatus.querySelector(".value")
          let progressLength =  progressStatus.querySelector(".all-value")
          if(progressValue && progressLength){
            progressValue.innerHTML = parseInt(activeStep.getAttribute("data-step"))
            progressLength.innerHTML = stepsQuantity - 1
          }
          progressStatus.style.marginLeft = (100 / (stepsQuantity - 1)) * (parseInt(activeStep.getAttribute("data-step"))- 1)  + "%"
          progressStatus.style.width = (100 / (stepsQuantity - 1)) + "%"
          progressFill.style.width = (100 / (stepsQuantity - 1)) * (parseInt(activeStep.getAttribute("data-step"))- 1) + "%"
        }
      }
    }
    // progressbar count function end
    countQuizeProgress(stepsQuantity)

    // stepper
    modalQuizeSteps.forEach(stepbox=>{
      let stepInfo = stepbox.querySelector(".quize-stepper__item-info")
      let stepNavigation = stepbox.querySelector(".quize-stepper__navigation")
      let stepQuizeInput = stepbox.querySelectorAll(".quize-stepper-radio")
      let stepPrevBtn = stepbox.querySelector(".quize-stepper__prev")
      let stepNextBtn = stepbox.querySelector(".quize-stepper__next")

      if(stepQuizeInput && stepInfo && stepNavigation){
        // show navigation and add text
        // let stepNavigationTitle = stepNavigation.querySelector('.quize-stepper__navigation-title')
        // let stepNavigationText = stepNavigation.querySelector('.quize-stepper__navigation-text')
        stepQuizeInput.forEach(quizeRadio=>{
          quizeRadio.addEventListener("change",function(){
            if(quizeRadio.checked){
              stepInfo.classList.remove("active")
              const promiseHide = new Promise((resolve,reject)=>{
                setTimeout(function(){
                  resolve(stepInfo.classList.add("disable-display"))
                },200)
              })
              promiseHide.then(()=>{
                // if(stepNavigationText && quizeRadio.hasAttribute("data-text")){
                //   stepNavigationText.innerHTML = quizeRadio.getAttribute("data-text")
                // }
                // if(stepNavigationTitle && quizeRadio.hasAttribute("data-title")){
                //   stepNavigationTitle.innerHTML = quizeRadio.getAttribute("data-title")
                // }
                stepNavigation.classList.remove("disable-display")
                setTimeout(function(){stepNavigation.classList.add("active")},10)
              })
            }
          })
        })
        // show navigation and add text end

        // back to step
        stepPrevBtn && stepPrevBtn.addEventListener("click",function(){
          stepNavigation.classList.remove('active')
          const promiseBack = new Promise((resolve,reject)=>{
            setTimeout(function(){
              resolve(stepNavigation.classList.add("disable-display"))
            },200)
          })
          promiseBack.then(()=>{
            stepInfo.classList.remove("disable-display")
            stepQuizeInput.forEach(elInput=>{
              if(elInput.checked){
                elInput.checked = false
              }
            })
            setTimeout(function(){stepInfo.classList.add("active")},10)
          })
        })
        // back to step end

        // next step
        stepNextBtn && stepNextBtn.addEventListener("click",function(){
          let currentStep = document.querySelector(".quize-stepper__item.active")
          if(currentStep && currentStep.classList.contains('active')){
            modalQuizeSteps.forEach(steper=>{
              if(stepNextBtn.getAttribute("data-step") == steper.getAttribute("data-step")){
                currentStep.classList.remove("active")
                const promiseNext = new Promise((resolve,reject)=>{
                  setTimeout(function(){
                    resolve(currentStep.classList.add("disable-display"))
                  },200)
                })
                promiseNext.then(()=>{
                  if(parseInt(stepNextBtn.getAttribute("data-step")) >= stepsQuantity){
                    let progressBlock = document.querySelector(".quize-stepper-progress")
                    if(progressBlock){
                      progressBlock.classList.add("disable-display")
                    }
                  }
                  steper.classList.remove("disable-display")
                  setTimeout(function(){
                    steper.classList.add("active")
                    countQuizeProgress(stepsQuantity)
                  },10)
                })

              }
            })

          }
        })
        // next step end
      }
    })
    // stepper end
  }
  // quize popup end ======================================================

  document.getElementById("year").innerHTML = new Date().getFullYear();
})
